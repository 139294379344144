export const useAgents = () => useState("agents", () => {
    return ref([]);
});

export const useAgentOrder = () => useState("agentOrder", () => {
    return ref([]);
});

export const useDefaultAgentSettings = () => useState("agentsDefaultSettings", () => {
    return ref(
        {
            "selectedHotlines": [], 
            "type": "agent", 
            "wrap-up-time": 40, 
            "max-no-answer": 3, 
            "reject-delay-time": 10, 
            "busy-delay-time": 60, 
            "no-answer-delay-time": 60, 
            "call-timeout": 15, 
            "bypass_media_after_bridge": true, 
        }
    )
});

export const useAttachables = () => useState("attachableAgents", () => {
    return ref([]);
});