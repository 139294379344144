<template>
    <section class="py-3 py-md-5">
    <div class="container">

        <h5>Manual top-up</h5>
    <p>
        Please enter the amount you want to add to your balance. This is a one-time payment. The top-up balance is used when your monthly balance is exhausted. It carries over to subsequent months.
    </p>
    <div class="row  mt-4">
      <div class="col-xl-12 col-lg-5 col-sm-5">
        <div class="input-group">
          
          <input v-model="topUpParams.topup" type="number"  class="form-control text-center" aria-label="switch"/>
          <span class="input-group-text">$</span>
        </div>
      </div>
    </div>
     
    </div>
  </section>
  <div> 
    <!-- v-if="props.payment_type=='initial'" -->
    
  </div>
       
  <!-- {{topUpParams}} -->
  <!-- {{props.modelValue}} -->
  </template>
  
  <script setup>
    
    const emit = defineEmits(['update:modelValue']);
  
    const props = defineProps({
      modelValue: { type: Object, default: {topup:20} },
      payment_type: {type: String, default:"topup"}
  });
  const topUpParams = ref(props.modelValue)
  watch(topUpParams, (newValue) => {
      emit('update:modelValue', newValue);
  }, { deep: true });
  
  </script>