<template>
    <section class="py-3 py-md-5">
    <div class="container">

        <h5 class="mb-5">Manual top-up</h5>
    
      <div class="row justify-content-center">
        <div class="col-12 col-lg-12 col-xl-12 col-xxl-12">
          <div class="row mb-3">
            <div class="col-12">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      
                      <th scope="col" class="text-uppercase">Product</th>
                      <th scope="col" class="text-uppercase text-center ">Qty</th>
                      <th scope="col" class="text-uppercase text-end">Unit Price</th>
                      <th scope="col" class="text-uppercase text-end">Amount</th>
                    </tr>
                  </thead>
                  <tbody  v-if="props.payment_type=='topup'" class="table-group-divider">
                    <tr>
                      <td>Call Center Balance Top-Up (One-Time)</td>
                      <td class="text-center">1</td>
                      <td class="text-end">${{props.modelValue.topup}}.00</td>
                      <td class="text-end">${{props.modelValue.topup}}.00</td>
                    </tr>
                    <tr>
                      <th scope="row" colspan="3" class="text-uppercase text-end">Total</th>
                      <td class="text-end">${{props.modelValue.topup}}.00</td>
                    </tr>
                  </tbody>
  
                  
  
  
                </table>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </section>
  <div> 
    <!-- v-if="props.payment_type=='initial'" -->
    
  </div>
       
  <!-- {{topUpParams}} -->
  <!-- {{props.modelValue}} -->
  </template>
  
  <script setup>
    
    const emit = defineEmits(['update:modelValue']);
  
    const props = defineProps({
      modelValue: { type: Object, default: {topup:20} },
      payment_type: {type: String, default:"topup"}
  });
  const topUpParams = ref(props.modelValue)
  watch(topUpParams, (newValue) => {
      emit('update:modelValue', newValue);
  }, { deep: true });
  
  </script>